<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.document_authority") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="documentAuthorities"
      :search="search"
      class=" agTable"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
                      :footer-props="{
                       itemsPerPageText: $t('general.table.itemsPerPageText'),
                       pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
                      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            onkeypress="return (event.charCode != 34)"
            v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch" 
            style="max-width: 25em;"
            color="primary"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('general.titles.filter')"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                tile
                outlined
                color="primary"
                class="mb-2"
                v-on="on"
              >
                <v-icon small color="primary">mdi-plus</v-icon>
                {{ $t("general.buttons.new") }}
              </v-btn>
            </template>
            <v-card :light="true" color="white">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceName" v-on:keyup.ctrl.86="replaceName"
                          color="primary"
                          v-model="editedItem.name"
                          :label="$t('documentAuthority.fields.name')"
                          required
                          class="required"
                          :rules="requireAndMaxRules(max)"
                          :counter="max"
                          maxlength="max"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceEmail" v-on:keyup.ctrl.86="replaceEmail"
                          color="primary"
                          v-model="editedItem.email"
                          :label="$t('documentAuthority.fields.email')"
                          required
                          class="required"
                          :rules="requireRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceDocumentKey" v-on:keyup.ctrl.86="replaceDocumentKey"
                          color="primary"
                          v-model="editedItem.document_key"
                          :label="
                            $t('documentAuthority.fields.identificationType')
                          "
                          required
                          class="required"
                          :rules="requireRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceDocument_value" v-on:keyup.ctrl.86="replaceDocument_value"
                          color="primary"
                          v-model="editedItem.document_value"
                          :label="
                            $t('documentAuthority.fields.identificationValue')
                          "
                          required
                          class="required"
                          :rules="requireRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceTelephone" v-on:keyup.ctrl.86="replaceTelephone"
                          color="primary"
                          v-model="editedItem.telephone"
                          :label="$t('documentAuthority.fields.telephone')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small tile outlined color="gray" @click="close">
                  {{ $t("general.buttons.cancel") }}
                </v-btn>
                <v-btn
                  small
                  tile
                  outlined
                  color="primary"
                  :disabled="!valid"
                  @click="save"
                >
                  {{ $t("general.buttons.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon color="neuter" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="neuter" small @click="deleteItemDialog(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="primary" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import DocumentAuthorityService from "../../../services/DocumentAuthorityService";

export default {
  data: () => ({
    profile: null,
    dialog: false,
    editedIndex: -1,
    editedItem: {
      name: "",
      company_name: "",
      email: "",
      document_key: "",
      document_value: "",
      telephone: "",
    },
    defaultItem: {
      name: "",
      company_name: "",
      email: "",
      document_key: "",
      document_value: "",
      telephone: "",
    },
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    valid: false,
    max: 40,
    deletingItem: {},
    search: "",
  }),

  computed: {
    ...mapGetters({
      documentAuthorities: "document_authority/documentAuthorities",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
    }),
    headers: () => [
      { text: i18n.t("documentAuthority.fields.name"), value: "name" },
      { text: i18n.t("documentAuthority.fields.email"), value: "email" },
      {
        text: i18n.t("documentAuthority.fields.identificationType"),
        value: "document_key",
      },
      {
        text: i18n.t("documentAuthority.fields.identificationValue"),
        value: "document_value",
      },
      {
        text: i18n.t("documentAuthority.fields.telephone"),
        value: "telephone",
      },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "end",
      },
    ],
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("general.titles.new")
          : i18n.t("general.titles.edit")) +
        " " +
        i18n.t("menu.document_authority")
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      fetchListDocumentAuthority:
        "document_authority/fetchListDocumentAuthority",
      saveDocumentAuthority: "document_authority/saveDocumentAuthority",
      languajeKeys: "general/getLanguajeKeys",
    }),
    initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.fetchListDocumentAuthority([this.profile, this.$toast]);
    },
    editItem(item) {
      this.editedIndex = this.documentAuthorities.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem() {
      this.dialogClose();
      DocumentAuthorityService.delete(this.deletingItem.id)
        .then((resp) => {
          this.$toast.success(
            this.$t("documentAuthority.notification.deleted"),
            {
              icon: 'mdi-check-circle',
              queueable: true
            }
          );
        })
        .catch((err) => {
          if (err.message.includes("406")) {
            this.$toast.info(this.$t("documentAuthority.notification.inUse"), {
              icon: 'mdi-information-slab-circle',
              queueable: true
            });
          } else {
            this.$toast.error(
              this.$t("documentAuthority.notification.deletedError"),
              {
                queueable: true,
              }
            );
          }
        })
        .finally(() => {
          this.fetchListDocumentAuthority([this.profile, this.$toast]);
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      let item = Object.assign({}, this.editedItem);
      item.company_manager = this.profile.company_id;
      this.saveDocumentAuthority([item, this.editedIndex, this.$toast]).finally(
        () => {
          this.fetchListDocumentAuthority([this.profile, this.$toast]);
          this.$toast.success(i18n.t("documentAuthority.notification.save"), {
            queueable: true
          });
          this.close();
        }
      );
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceName(event){
      this.editedItem.name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceEmail(event){
      this.editedItem.email =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDocumentKey(event){
      this.editedItem.document_key =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceDocument_value(event){
      this.editedItem.document_value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceTelephone(event){
      this.editedItem.telephone =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style scoped></style>
